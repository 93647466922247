@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");
* {
  font-family: "MyCustomFont" !important;
}
html {
  scroll-behavior: smooth;
}

.dividernav {
  height: 0.6px;
  background-color: #4a4a4a;
}
body {
  background-color: #ffffff !important;
  font-family: "MyCustomFont" !important;
  overflow-x: hidden !important;
}
@font-face {
  font-family: "MyCustomFont";
  src: url("./fonts/Parsi-Regular.ttf") format("truetype");
}

.enditall {
  justify-content: end;
  text-align: end;
}

/* src/components/VideoBackground.css */
.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; /* To ensure it's behind other content */
}

.video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire container */
  transform: translate(-50%, -50%) scale(1); /* Scale down to zoom out */
}

.glowing-logos {
  width: 180px;
  position: relative;
  animation: glow 1.5s infinite alternate;
}

.glowing-logo {
  width: 300px;
  position: relative;
  animation: glow 1.5s infinite alternate;
}
.padnav {
  padding: 10px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(
    122,
    231,
    255,
    0.401
  ); /* Light background with transparency */
  backdrop-filter: blur(16px); /* Blurry effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
}

.padnav {
  padding: 3px;
}

.centeritall_starts {
  text-align: end;
  justify-content: end;
  align-items: end;
  display: flex;
  position: relative;
  top: 6px;
}

.centeritall_start {
  position: relative;
  top: 6px;
  left: 10px;
}

.fco {
  color: #000 !important;
  font-size: 40px !important;
}
.fcosb {
  color: #00000000 !important;
  font-size: 40px !important;
}

.fcos {
  color: #000 !important;
  font-size: 30px !important;
}
.padnavbo {
  padding: 10px;
  position: fixed;
  width: 100%;
  bottom: 0; /* Changed from top: 0 to bottom: 0 */
  left: 0;
  z-index: 1000;
  background: rgba(
    122,
    231,
    255,
    0.401
  ); /* Light background with transparency */
  backdrop-filter: blur(16px); /* Blurry effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
}

/* Optional second .padnavbo class definition */
/* This can be removed if not needed, as it would overwrite the previous padding */
.padnavbo {
  padding: 3px;
}

.center-link {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  background-color: #00000000;
  color: white;
  height: 520px;
  width: 500px;
  text-decoration: none;
  font-size: 18px;
  border-radius: 1000px;
}

.center-link:hover {
  background-color: rgba(160, 0, 0, 0);
}

.color_of_indicator {
  background-color: rgba(0, 136, 64, 0.478);
  border-radius: 10px;
}

.back_of_drawer {
}
.css-k9imp5 {
  z-index: 1000 !important;
  background: rgba(
    122,
    231,
    255,
    0.401
  ) !important; /* Light background with transparency */
  backdrop-filter: blur(10px) !important; /* Blurry effect */
  -webkit-backdrop-filter: blur(10px) !important; /* For Safari */
}
.css-yb0lig {
  text-align: center;
  font-size: 27px !important;
  color: #fff;
  font-weight: 600 !important;
}

.css-wf16b5 {
  z-index: 1000 !important;
  background: rgba(
    122,
    231,
    255,
    0.401
  ) !important; /* Light background with transparency */
  backdrop-filter: blur(10px) !important; /* Blurry effect */
  -webkit-backdrop-filter: blur(10px) !important; /* For Safari */
}
.css-oqbj7d {
  z-index: 1000 !important;
  background: rgba(
    122,
    231,
    255,
    0.401
  ) !important; /* Light background with transparency */
  backdrop-filter: blur(10px) !important; /* Blurry effect */
  -webkit-backdrop-filter: blur(10px) !important; /* For Safari */
}

.css-10hburv-MuiTypography-root {
  margin: 0;
  font-weight: 400;
  color: #ffffff;
  font-size: 25px !important;
  font-weight: 900 !important;
  text-align: center !important;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: block;
  transition: color 0.3s ease; /* Smooth transition effect */
}

.css-10hburv-MuiTypography-root:hover {
  color: #000000; /* Change the color to red on hover */
}

.center_head_story {
  text-align: start;
  color: #fff;
  font-size: 40px !important;
  font-weight: 700;
  position: relative;
  left: 10px;
}
@media screen and (min-width: 1000px) {
  .center_head_story {
    text-align: start;
    color: #fff;
    font-size: 60px !important;
    font-weight: 700;
    position: relative;
    left: 0px;
  }
}

.center_under_story {
  text-align: start;
  color: #fff;
  font-size: 15px;
  font-weight: 300;
}
@media screen and (min-width: 1000px) {
  .center_under_story {
    text-align: start;
    color: #fff;
    font-size: 26px;
    font-weight: 300;
  }
}

.center_head_story_under {
  text-align: start;
  color: #57d5ff;
  font-size: 22px;
  font-weight: 700;
}

@media screen and (min-width: 1000px) {
  .center_head_story_under {
    text-align: start;
    color: #57d5ff;
    font-size: 40px;
    font-weight: 700;
  }
}

.center_head_story_under1 {
  text-align: start;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
}

@media screen and (min-width: 1000px) {
  .center_head_story_under1 {
    text-align: start;
    color: #ffffff;
    font-size: 30px;
    font-weight: 500;
  }
}

.from_top {
  position: relative;
  top: 0px;
}

@media screen and (min-width: 1000px) {
  .from_top {
    position: relative;
    top: 90px;
  }
}

.xoe_bg2 {
  position: relative;
  background-image: url("./backgrounds/bg2.svg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

/* Pseudo-element for the glass-like overlay */
.xoe_bg2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Adjust the color and opacity for the glass effect */

  z-index: 1; /* Places the overlay above the background but below the content */
}

/* Ensure content within .xoe_bg1 is above the overlay */
.xoe_bg2 > * {
  z-index: 1;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .xoe_bg2 {
    background-image: url("./backgrounds/bg2.svg");
    background-position: center; /* Center the mobile background image */
    background-size: cover; /* Ensure the background image covers the element */
  }
}

.btn_trait {
  background-color: #59479d;
  color: #fff;
  padding: 10px;
  text-align: center;
  font-size: 22px;
  border-radius: 50px;
  width: 200px;
  font-weight: 700;
}

.centeritall {
  justify-content: center;
  align-items: center;
  display: flex;
}

.mouth1 {
  background-color: #07558d;
  border-radius: 20px;
  padding: 20px;
  color: #fff;
  font-size: 20px;
}

.mouth2 {
  background-color: #50c09d;
  border-radius: 20px;
  padding: 20px;
  color: #fff;
  font-size: 20px;
}

.xoe_bg4 {
  position: relative;
  background-image: url("./backgrounds/bg4.svg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

/* Pseudo-element for the glass-like overlay */
.xoe_bg4::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Adjust the color and opacity for the glass effect */

  z-index: 1; /* Places the overlay above the background but below the content */
}

/* Ensure content within .xoe_bg1 is above the overlay */
.xoe_bg4 > * {
  z-index: 1;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .xoe_bg4 {
    background-image: url("./backgrounds/bg4.svg");
    background-position: center; /* Center the mobile background image */
    background-size: cover; /* Ensure the background image covers the element */
  }
}

.roadmap_head {
  font-size: 80px;
  font-weight: 700;
  color: #fff;
}

@media screen and (min-width: 1000px) {
  .roadmap_head {
    font-size: 100px;
    font-weight: 700;
    color: #fff;
  }
}

.xoe_bg3 {
  position: relative;
  background-image: url("./backgrounds/bg3.svg");
  background-attachment: fixed;
  background-size: cover;

  background-repeat: no-repeat;
}

/* Pseudo-element for the glass-like overlay */
.xoe_bg3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Adjust the color and opacity for the glass effect */

  z-index: 1; /* Places the overlay above the background but below the content */
}

/* Ensure content within .xoe_bg1 is above the overlay */
.xoe_bg3 > * {
  z-index: 1;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .xoe_bg3 {
    background-image: url("./backgrounds/bg3.svg");
    background-position: center; /* Center the mobile background image */
    background-size: cover; /* Ensure the background image covers the element */
  }
}

.target_audi {
  color: #73ccd8;
  font-size: 30px;
  font-weight: 500;
}

@media screen and (min-width: 1000px) {
  .target_audi {
    color: #73ccd8;
    font-size: 40px;
  }
}
.target_audi_image {
  width: 80px;
}

@media screen and (min-width: 1000px) {
  .target_audi_image {
    width: 130px;
  }
}
.target_audi_text {
  font-size: 21px;
  color: #fff;
}

@media screen and (min-width: 1000px) {
  .target_audi_text {
    font-size: 25px;
    color: #fff;
  }
}

.xoe_token_head {
  color: #73ccd8;
  font-size: 30px;
}

@media screen and (min-width: 1000px) {
  .xoe_token_head {
    color: #73ccd8;
    font-size: 60px;
  }
}

.tokens_divs {
  background-color: #73ccd8;
  padding: 8px;
  font-size: 30px;
  color: #000000;
  font-weight: 800;
  width: 300px;
  position: relative;
  clip-path: polygon(0 -160%, 0% 100%, 100% 100%);
  overflow: hidden; /* Ensures child elements do not overflow */
}

.tokens_divsb {
  background-color: #73ccd8;
  padding: 8px;
  font-size: 30px;
  color: #000000;
  font-weight: 800;
  width: 430px;
  position: relative;
  clip-path: polygon(0 -160%, 0% 100%, 100% 100%);
  overflow: hidden; /* Ensures child elements do not overflow */
}

.bg_image_removed {
  justify-content: center;
  align-items: center;

  display: flex;
}

.bg_image_removed_border {
  border: 10px solid #006be4;
}

.center-linkm {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  background-color: rgba(198, 0, 0, 0);
  color: white;
  height: 500px;
  width: 360px;
  text-decoration: none;
  font-size: 18px;
  border-radius: 1000px;
}

.center-linkm:hover {
  background-color: rgba(198, 0, 0, 0);
}

.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  background-color: #000 !important;
}

.footer_bg {
  background-color: #e3e8ec;
}

.under_footer {
  background-color: #808080;
  height: 40px;
}

.logo_footer {
  width: 200px;
}

@media screen and (min-width: 1000px) {
  .logo_footer {
    width: 300px;
  }
}

.centeritall_flex {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  top: 6px;
}

.docs_button {
  background-color: #e3e8ec;
  border: 2px solid #e3e8ec;
  padding: 20px;
  font-size: 21px;
  cursor: pointer;
  color: #808080;
  border-radius: 10px;
  width: 300px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.docs_button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  transform: translateX(-100%);
  transition: all 0.3s ease;
}

.docs_button:hover::before {
  transform: translateX(100%);
}

.docs_button:hover {
  background-color: #d0d7de;
  border-color: #d0d7de;
  color: #19417c;
}

@media screen and (min-width: 1000px) {
  .docs_button {
    background-color: #e3e8ec;
    border: 2px solid #e3e8ec;
    padding: 20px;
    font-size: 27px;
    cursor: pointer;
    color: #808080;
    border-radius: 10px;
    width: 350px;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
  }

  .docs_button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0)
    );
    transform: translateX(-100%);
    transition: all 0.3s ease;
  }

  .docs_button:hover::before {
    transform: translateX(100%);
  }

  .docs_button:hover {
    background-color: #d0d7de;
    border-color: #d0d7de;
    color: #19417c;
  }
}

.xoe_bg1 {
  position: relative;
  background-image: url("./backgrounds/bg2.svg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

/* Pseudo-element for the glass-like overlay */
.xoe_bg1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Adjust the color and opacity for the glass effect */
}

/* Ensure content within .xoe_bg1 is above the overlay */
.xoe_bg1 > * {
  z-index: 1;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .xoe_bg1 {
    background-image: url("./backgrounds/bg2.svg"); /* Replace with your mobile version image */
    background-position: center; /* Center the mobile background image */
    background-size: cover; /* Ensure the background image covers the element */
  }
}

.intro_documents {
  text-align: center;
  align-items: center;
  font-size: 30px;
  color: #000000;
}

@media screen and (min-width: 1000px) {
  .intro_documents {
    font-size: 45px;

    color: #000000;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: "<percentage>";
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: "<percentage>";
  initial-value: 0%;
  inherits: true;
}

:root {
  --d: 2500ms;
  --angle: 90deg;
  --gradX: 100%;
  --gradY: 50%;
  --c1: rgba(168, 239, 255, 1);
  --c2: rgba(0, 0, 0, 0.1);
}

.box {
  border-radius: 10px;
  border: 0.45rem solid;

  border-image: conic-gradient(
      from var(--angle),
      var(--c2),
      var(--c1) 0.1turn,
      var(--c1) 0.15turn,
      var(--c2) 0.25turn
    )
    30;
  animation: borderRotate var(--d) linear infinite forwards;
}

.box:nth-child(2) {
  border-image: radial-gradient(
      ellipse at var(--gradX) var(--gradY),
      var(--c1),
      var(--c1) 10%,
      var(--c2) 40%
    )
    30;
  animation: borderRadial var(--d) linear infinite forwards;
}

@keyframes borderRotate {
  100% {
    --angle: 420deg;
  }
}

@keyframes borderRadial {
  20% {
    --gradX: 100%;
    --gradY: 50%;
  }
  40% {
    --gradX: 100%;
    --gradY: 100%;
  }
  60% {
    --gradX: 50%;
    --gradY: 100%;
  }
  80% {
    --gradX: 0%;
    --gradY: 50%;
  }
  100% {
    --gradX: 50%;
    --gradY: 0%;
  }
}

.howtobuy_bg {
  width: 100%;
  background: linear-gradient(to top, #f0f3f5 60%, #c1c2c3 80%);
}

@media screen and (min-width: 1000px) {
  .howtobuy_bg {
    width: 100%;

    background: linear-gradient(to left, #f0f3f5 70%, #e3e8ec 70%);
  }
}

.htb_shead {
  color: #808080;
  font-size: 20px;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 1000px) {
  .htb_shead {
    font-size: 25px;
  }
}

.htb_shead::before {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  color: #000;
  transition: top 0.5s ease-in-out;
  white-space: nowrap;
}

.htb_shead:hover::before {
  top: 0;
}

.htb_shead::after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  color: inherit;
  transition: top 0.5s ease-in-out;
  white-space: nowrap;
}

.htb_shead:hover::after {
  top: -100%;
}

.htb_uhead {
  color: #808080;
  font-size: 14px;
}
@media screen and (min-width: 1000px) {
  .htb_uhead {
    font-size: 16px;
    color: #808080;
  }
}

.htb_head {
  font-size: 30px;
  color: #000;
}

@media screen and (min-width: 1000px) {
  .htb_head {
    font-size: 50px;
    color: #000;
  }
}

.trait_head {
  font-size: 30px;
  color: #000;
  text-align: center;
}

@media screen and (min-width: 1000px) {
  .trait_head {
    font-size: 50px;
    color: #000;
  }
}

.background_traits_detail {
  background-image: url("./backgrounds/traitsbgs.png");
  background-size: contain; /* Ensures the background image scales down if it's too large */
  background-position: center; /* Keeps the background image centered */
  background-repeat: no-repeat; /* Prevents the background image from repeating */
  width: 100%; /* Makes the element fully responsive */
  height: 100vh; /* Sets the height to the full viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar_home_bg {
  background-color: #e3e8ec;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.nav_link {
  color: #000;
  text-decoration: none !important;
}

.nav_socials {
  font-size: 20px;
  top: 5px;
  position: relative;
}
.head_robot_top {
  width: 100%;
  position: relative;
  bottom: 20px;
}
@media screen and (min-width: 1000px) {
  .head_robot_top {
    width: 100%;
  }
}
.head_robot_top_white {
  width: 100%;
  position: relative;
  top: 30px;
  z-index: 1;
  left: 50px;
}
@media screen and (min-width: 1000px) {
  .head_robot_top_white {
    width: 100%;
    position: relative;
    top: 180px;
    left: 200px;
  }
}

.navbar_home_bg_head {
  background-color: #e3e8ec;

  position: relative;
}

.main_buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;

  align-items: center; /* Centers items vertically */
  justify-content: space-between; /* Distributes items horizontally, adjust as needed */
  text-align: center; /* Centers the text within its container */
  padding: 10px; /* Adjust padding as needed */
  background: rgba(255, 255, 255, 0.263); /* Semi-transparent black */
  backdrop-filter: blur(10px); /* Blur effect for frosted glass look */
  -webkit-backdrop-filter: blur(10px); /* For Safari support */
  color: white; /* Text color for contrast */
  border-top: 1px solid rgba(255, 255, 255, 0); /* Optional border for better separation */
  box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.2); /* Optional shadow for depth */
}

@media screen and (min-width: 1000px) {
  .main_buttons {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 220px;
    text-align: center; /* Assuming you want to center the text */
    padding: 10px; /* Adjust padding as needed */
    background: rgba(255, 255, 255, 0.263); /* Semi-transparent black */
    backdrop-filter: blur(10px); /* Blur effect for frosted glass look */
    -webkit-backdrop-filter: blur(10px); /* For Safari support */
    color: white; /* Text color for contrast */
    border-top: 1px solid rgba(255, 255, 255, 0); /* Optional border for better separation */
    box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.2); /* Optional shadow for depth */
  }
}

.main_links_buttons {
  font-size: 11px;
  position: relative;
  text-align: center;
  top: 5px;
  color: #4a4a4ac7 !important;
  text-decoration: none !important;
}

@media screen and (min-width: 1000px) {
  .main_links_buttons {
    font-size: 30px;
    position: relative;
    text-align: center;
    top: 65px;
    color: #4a4a4ac7 !important;
    text-decoration: none !important;
  }
}

.marquee_img {
  width: 250px;
}

@media screen and (min-width: 1000px) {
  .marquee_img {
    width: 300px;
  }
}

.back_big_robot {
  background-image: url("./backgrounds/robot.svg");
  background-size: cover; /* Ensures the background image scales down if it's too large */
  background-position: center; /* Keeps the background image centered */
  background-repeat: no-repeat; /* Prevents the background image from repeating */
  width: 100%; /* Makes the element fully responsive */
  height: 100%; /* Sets the height to the full viewport height */
}

@media screen and (min-width: 1000px) {
  .back_big_robot {
    background-image: url("./backgrounds/robot.svg");
    background-size: cover; /* Ensures the background image scales down if it's too large */
    background-position: center; /* Keeps the background image centered */
    background-repeat: no-repeat; /* Prevents the background image from repeating */
    width: 100%; /* Makes the element fully responsive */
    height: 1900px; /* Sets the height to the full viewport height */
  }
}

.end_line {
  display: flex;
  justify-content: flex-end; /* Aligns content to the end of the container */
}

.glassy_back {
  background: rgba(255, 255, 255, 0.263); /* Semi-transparent black */
  backdrop-filter: blur(10px); /* Blur effect for frosted glass look */
  -webkit-backdrop-filter: blur(10px); /* For Safari support */
}

.card {
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.05));
  transition: transform 0.3s ease;
  border-radius: 15px;
  z-index: -1;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
}

.card:hover::before {
  transform: scale(1.1);
}

.cards {
  background-color: #ffffff37;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(171, 171, 171, 0.526); /* Adds shadow with horizontal offset, vertical offset, blur radius, and color */
}

@media screen and (min-width: 1000px) {
  .cards {
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.526); /* Adds shadow with horizontal offset, vertical offset, blur radius, and color */
  }
}

.top_buy_robot {
  position: relative;
  top: 1px;
}

@media screen and (min-width: 1000px) {
  .top_buy_robot {
    position: relative;
    top: 55px;
  }
}

.nav_elements {
  position: relative;
}
@media screen and (min-width: 1000px) {
  .nav_elements {
    position: relative;
    left: 0px;
  }
}
.glassy_back {
}

.container {
}

.video-container {
  width: 100%;

  margin: 0 auto;
  position: relative;
}

.react-player {
  border-radius: 10px;
  overflow: hidden;
}

.progress-slider {
  width: 100%;
  height: 5px;
  background: #acacac;
  outline: none;
  margin: 10px 0;
  -webkit-appearance: none;
}

.progress-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 10px;
  height: 20px;
  background: #4a4a4a;
  cursor: pointer;
}

.progress-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #4a4a4a;
  cursor: pointer;
}

.controls {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.play-pause-btn,
.volume-btn {
  background-color: #1f1f1f;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.play-pause-btn:hover,
.volume-btn:hover {
  background-color: #333333;
}

.fixinbottom {
  position: fixed;
  bottom: 0;
  left: 0; /* Or adjust as needed */
  width: 100%; /* Or adjust as needed */
  background-color: #fff; /* Adjust as needed */
  /* Add any additional styling you need */
}

.main_buttonsd {
  top: 0;
  width: 100%;
  position: fixed;
  height: 60px;
  text-align: center; /* Assuming you want to center the text */
  padding: 10px; /* Adjust padding as needed */
  background: rgba(255, 255, 255, 0.263); /* Semi-transparent black */
  backdrop-filter: blur(10px); /* Blur effect for frosted glass look */
  -webkit-backdrop-filter: blur(10px); /* For Safari support */
  color: white; /* Text color for contrast */
  border-top: 1px solid rgba(255, 255, 255, 0); /* Optional border for better separation */
  box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.2); /* Optional shadow for depth */
}

@media screen and (min-width: 1000px) {
  .main_buttonsd {
    bottom: 0;
    width: 100%;
    height: 100px;
    text-align: center; /* Assuming you want to center the text */
    padding: 10px; /* Adjust padding as needed */
    background: rgba(255, 255, 255, 0.615); /* Semi-transparent black */
    backdrop-filter: blur(10px); /* Blur effect for frosted glass look */
    -webkit-backdrop-filter: blur(10px); /* For Safari support */
    color: white; /* Text color for contrast */
    border-top: 1px solid rgba(255, 255, 255, 0); /* Optional border for better separation */
    box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.2); /* Optional shadow for depth */
  }
}

.main_links_buttonsd {
  font-size: 11px;
  position: relative;
  text-align: center;
  top: 5px;
  color: #000000 !important;
  text-decoration: none !important;
}

@media screen and (min-width: 1000px) {
  .main_links_buttonsd {
    font-size: 30px;
    position: relative;
    text-align: center;
    top: 15px;
    color: #000000 !important;
    text-decoration: none !important;
  }
}

.htb_uheadt {
  color: #808080;
  font-size: 20px;
}
@media screen and (min-width: 1000px) {
  .htb_uheadt {
    font-size: 16px;
    color: #808080;
  }
}

/* Add this CSS to your stylesheet */
.buffering-icon {
  position: absolute;
  top: 30%;
  left: 44%;
  transform: translate(-50%, -50%);
  font-size: 3rem; /* Adjust size as needed */
  animation: spin 1s linear infinite;
  color: #fff; /* Adjust color as needed */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 1000px) {
  /* Add this CSS to your stylesheet */
  .buffering-icon {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem; /* Adjust size as needed */
    animation: spin 1s linear infinite;
    color: #fff; /* Adjust color as needed */
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

/* Loading.css */
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white; /* Or any color you prefer */
  z-index: 9999;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.center-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(0, 0, 0);
  font-size: 1em;
  padding: 10px 20px;

  border-radius: 5px;
}

.tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  pointer-events: none;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -150%);
}

.center-text {
  position: absolute;
  padding: 1px;
  background-color: #0081b9d4;
  top: 63%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px; /* Adjust font size as needed */
  font-weight: bold;
  color: white;
  text-align: center;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Glowing effect */
  z-index: 10;
}

.mint-nft-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height to center vertically */
  background-color: #f9f9f9;
}

.grid-container {
  width: 100%;
  max-width: 1200px; /* Max width for the content */
  margin: 0 auto; /* Center container horizontally */
}

.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nft-image {
  width: 100%;
  max-width: 500px; /* Adjust size as needed */
  height: auto;
  border-radius: 10px;
}

.mint-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 500px;
  min-height: 500px;
}

.mint-form h1 {
  margin-bottom: 10px;
  font-size: 1.8rem;
  color: #333;
}

.mint-form span {
  font-size: 18px;
  color: #555;
  line-height: 1.5;
}

.number-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.decrement-button button {
  align-items: "center";
  justify-items: "center";
}

.number-input-container button {
  width: 40px;
  height: 40px;
  border: none;
  background-color: #000;
  color: white;
  border-radius: 50%;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  text-align: center;
}

.number-input-container input {
  width: 60px;
  text-align: center;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

button[type="submit"] {
  padding: 10px 15px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}
