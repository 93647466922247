/* Keyframes for the pop-up animation */
@keyframes popup {
  0% {
    transform: scale(0.5); /* Start from smaller scale */
    opacity: 0;
  }
  100% {
    transform: scale(1); /* End at normal scale */
    opacity: 1;
  }
}

.fixed-button {
  position: fixed;
  left: 0;
  top: 20%; /* Adjust as needed */
  background-color: #000000;
  color: #fff;
  padding: 10px;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 1000; /* Ensure the button stays above other content */
}

.button-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.drawer {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 100%; /* Start from the right edge of the button */
  top: 0;
  width: 200px; /* Width of the drawer */
  background-color: #000000;
  border-radius: 0 5px 5px 0; /* Match the button's rounded corners */
  opacity: 0;
  visibility: hidden;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.drawer.open {
  opacity: 1;
  visibility: visible;
  transform: translateX(0); /* Move the drawer into view */
  animation: popup 0.3s ease-out; /* Apply the pop-up animation */
}

.drawer a {
  color: #fff;
  text-decoration: none;
  padding: 10px;
  border-bottom: 1px solid #444;
  transition: background-color 0.3s;
}

.drawer a:hover {
  background-color: #555;
}
